<template>
  <Button  template="icon-only" v-bind="$attrs">
      <icon :icon="icon"></icon>
  </Button>
</template>

<script>
import Button from '../_shared/button-skeleton.vue';
import Icon from 'vue-elements/icon/Icon.vue';

export default {
  name: 'ButtonIconOnly',
  components: {
    Button,
    Icon
  },
  props: {
    icon: {
      type: String,
      default: 'arrow-right-long-solid'
    },
  }
};
</script>
